import styled from "styled-components";

export const PageWrapper = styled.div`
  padding: 0 25px;

  .actions {
    text-align: center;
    a {
      padding-right: 10px;
    }
  }
`;
